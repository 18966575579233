<div class="notifications horizontal space10">
    <ng-template [ngForOf]="notifications" let-index="index" let-notif ngFor>
        <div class="anim-appear notif notif-{{notif.type.toLowerCase()}}">
            <i [ngClass]="{
               'fa-check-circle': notif.type.toLowerCase() == 'success',
               'fa-info-circle': notif.type.toLowerCase() == 'info',
               'fa-exclamation-circle': notif.type.toLowerCase() == 'warning',
               'fa-exclamation-triangle': notif.type.toLowerCase() == 'error',
               'fa-download': notif.type.toLowerCase() == 'filedownloaded'
               }" class="fa-solid fa-lg"></i>
            {{notif.time | fibaDate: 'HH:mm:ss'}}
            &nbsp;-&nbsp;
            {{notif.message}}

            <a (onload)="click()" *ngIf="notif.type.toLowerCase() == 'filedownloaded'" [href]="sanitize(notif.url)"
               download="{{notif.fileName}}" style="display:compact">
                {{notif.fileName}}
            </a>
            &nbsp;&nbsp;

            <i (click)="remove(index)" class="clickable fa-solid fa-close"></i>

        </div>
    </ng-template>
</div>
